export const numeroMenoresOpcionesTodas = [
  { id: "1", nombre: "1" },
  { id: "2", nombre: "2" },
  { id: "3", nombre: "3" },
  { id: "4", nombre: "4" },
  { id: "5", nombre: "5" },
  { id: "6", nombre: "6" },
  { id: "7", nombre: "7" },
  { id: "8", nombre: "8" },
  { id: "9", nombre: "9" },
  { id: "NOCONSTA", nombre: " " },
];

export const numeroMenoresOpciones = [
  { id: "1", nombre: "1" },
  { id: "2", nombre: "2" },
  { id: "3", nombre: "3" },
  { id: "4", nombre: "4" },
  { id: "5", nombre: "5" },
  { id: "6", nombre: "6" },
  { id: "7", nombre: "7" },
  { id: "8", nombre: "8" },
  { id: "9", nombre: "9" },
];
