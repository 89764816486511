/* eslint-disable @typescript-eslint/no-explicit-any */
type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export function diffObjects<Properties = PartialRecord<string, any>>(
  fields: (keyof Properties)[],
  initialValues: PartialRecord<keyof Properties, Properties[keyof Properties]>,
  values: PartialRecord<keyof Properties, Properties[keyof Properties]>
): PartialRecord<keyof Properties, Properties[keyof Properties]> {
  const keys = Object.keys(values) as (keyof Properties)[];
  const diff = keys.filter((key) => {
    return values[key] !== initialValues[key] && fields.includes(key);
  });

  return diff.reduce((acc, key) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    acc[key] = values[key]!;
    return acc;
  }, {} as Record<keyof Properties, Properties[keyof Properties]>);
}
