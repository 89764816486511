export const tipologiasFamiliaTodas = [
  { id: "NUMEROSA", nombre: "Numerosa" },
  { id: "MONOPARENTAL", nombre: "Monoparental" },
  {
    id: "VICTIMAVIOLENCIAGENERO",
    nombre:
      "Victima de violencia de género u otras formas de violencia contra las mujeres",
  },
  { id: "REFUGIADA", nombre: "Refugiada" },
  { id: "OTRASCARGAS", nombre: "Con otras cargas de cuidado" },
  { id: "OTRA", nombre: "Otra" },
  { id: "NOCONSTA", nombre: "No consta" },
];

export const tipologiasFamilia = [
  { id: "NUMEROSA", nombre: "Numerosa" },
  { id: "MONOPARENTAL", nombre: "Monoparental" },
  {
    id: "VICTIMAVIOLENCIAGENERO",
    nombre:
      "Victima de violencia de género u otras formas de violencia contra las mujeres",
  },
  { id: "REFUGIADA", nombre: "Refugiada" },
  { id: "OTRASCARGAS", nombre: "Con otras cargas de cuidado" },
  { id: "OTRA", nombre: "Otra" },
];
